import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


export default function Benefits() {
    const benefits = [
        {
            name: 'Medical Service',
            description:
                'We provide the services of an MBBS (government licensed) doctor to our employees and pay for the medicines of those who cannot afford it, along with their families.',
        },
        { name: 'Child-Care Service', description: '60% of our employees are women, many of whom are mothers of young children. Many times these women find it hard to balance work and family life. To support them, we have a designated nursery room, where child-care service is provided if required.' },
        {
            name: 'Canteen Service',
            description:
                'We provide meals to all our employees within a well-equipped canteen. The canteen holds an adequate seating capacity and meals are cooked in house with the maximum hygiene standards. We also have a water purifier plant that provides fresh and clean water.',
        },
        { name: 'Training', description: "We recognize that the personal development of each employee is important for the long-term success of the company. We fully support any further educational development or training our employees want to pursue, such as Post Graduate Degrees in Garments Business Management, Graphic design training and administration." },
        { name: 'Medical fund', description: "We invest part of our annual profits in a fund meant to cater to severe medical needs of our employees and their immediate families, such as life-threatening diseases and hazardous accidents. This allows many in the Sara Fashionwear community to finance situations otherwise irreversible and life altering." },
    ]
    return (

            <div className="max-w-2xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
                    <div>
                        <div className="border-b border-gray-200 pb-10">
                            <h2 className="font-medium text-gray-700">Employee benefits</h2>
                            <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                We are our people
                            </p>
                        </div>

                        <dl className="mt-10 space-y-10">
                            {benefits.map((feature) => (
                                <div key={feature.name}>
                                    <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                                    <dd className="mt-3 text-sm text-gray-700">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>

                    <div>
                        <div className="!aspect-w-1 !aspect-h-1 rounded-sm bg-gray-100 overflow-hidden">
                            <StaticImage
                                src="../images/compliance/benefits-1.jpg"
                                alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                                className="w-full h-full object-center object-cover"
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-4 sm:gap-6 sm:mt-6 lg:gap-8 lg:mt-8">
                            <div className="!aspect-w-1 !aspect-h-1 rounded-sm bg-gray-100 overflow-hidden">
                                <StaticImage
                                    src="../images/compliance/benefits-2.jpg"
                                    alt="Detail of temperature setting button on kettle bass with digital degree readout."
                                    className="w-full h-full object-center object-cover"
                                />
                            </div>
                            <div className="!aspect-w-1 !aspect-h-1 rounded-sm bg-gray-100 overflow-hidden">
                                <StaticImage
                                    src="../images/compliance/benefits-3.jpg"
                                    alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                                    className="w-full h-full object-center object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-x-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold pb-8 border-b text-gray-900 tracking-tight sm:text-3xl">Extra safety goes a long way</h3>
              <p className="mt-4 text-sm text-gray-700">
              To ensure immediate detection and isolation of smoke and fire related events, we have invested in the latest fire alarm and detection system, UL certified. In addition, an extensive inventory of fire extinguishers and high pressure hose reels are strategically located throughout the quarters to combat any kind of accidents. Our trained firefighters are put through a routine of exercises and mock fire alarms at least once a month, as per adhered compliance standards. 
              </p>
            </div>

            <div className="mt-10 lg:pr-4 relative lg:mt-0 lg:col-start-1">
              <StaticImage
                className="relative mx-auto"
                
                src="../images/compliance/benefits-4.jpg"
                alt=""
              />
            </div>
          </div>

            </div>
        </div>
    )
}