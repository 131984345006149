import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function ComplianceIntro() {
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-8 md:pb-16">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-left md:text-center">
              <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                <span className="block">A safe workplace</span>
              </h1>
              <p className="mt-3 mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl lg:max-w-3xl">
                We strive to be a pioneer towards mindful business in Bangladesh
              </p>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-100" />
          </div>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <StaticImage
              className="relative rounded-sm shadow-lg"
              src="../images/compliance-intro.jpg"
              alt="Textile factory workers"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-48">
          <h2 className="text-center text-gray-500 text-sm font-semibold uppercase tracking-wide">
            Recognized level of compliance on international standards
          </h2>
          <div className="mt-8 sm:px-12 md:px-0 grid grid-cols-2 gap-8 md:grid-cols-4">
            <div className="col-span-1 flex justify-center ">
              <StaticImage className="h-8 md:h-10" objectFit='contain' src="../images/compliance/sedex.png" alt="Sedex" />
            </div>
            <div className="col-span-1 flex justify-center">
              <StaticImage className="h-10 md:h-12" objectFit='contain' src="../images/compliance/oeko-tex.png" alt="Oeko-Tex" />
            </div>
            <div className="col-span-1 flex justify-center">
              <StaticImage className="h-10 md:h-12" objectFit='contain' src="../images/compliance/ilo.png" alt="ILO" />
            </div>
            <div className="col-span-1 flex justify-center ">
              <StaticImage className="h-10 md:h-12" objectFit='contain' src="../images/compliance/bsci.png" alt="BSCI" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}