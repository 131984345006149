import * as React from "react"

import Benefits from "../components/benefits"
import ComplianceIntro from "../components/complianceIntro"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CompliancePage = () => {
  return (
    <Layout>
      <Seo title='Sara Fashionwear'/>
      <ComplianceIntro />
      <Benefits />
    </Layout>
  )}

export default CompliancePage